import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      view: "userList",
      newMessage: "",
      unreadMessagesCount: 0,
      selectedUser: null,
      chats: {},
      newMessage: "",
      isMinimized: false,
      chatMinimized: false,
      position: { x: 0, y: 0 },
      isDragging: false,
      dragOffset: { x: 0, y: 0 },
      section_id: null,
      selectedLeadId: null,
      chatMessages: [],
      userId: JSON.parse(atob(localStorage.getItem("user"))).id,
      position: { x: 300, y: window.innerHeight - 50 },
      drag: false,
      offset: { x: 0, y: 0 },
    };
  },
  computed: {
    user() {
      return this.$store.state.userDetail;
    },
  },
  created() {
    EventBus.$on("PqOpenNotification", (notification) => {
      this.handlePqOpenNotification(notification);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updatePosition);
    EventBus.$off("PqOpenNotification");
  },
  mounted() {
    this.getMassages();
    this.scrollToBottom();
    this.updatePosition();
    window.addEventListener("resize", this.updatePosition);
  },
  watch: {
    messages() {
      this.scrollToBottom();
    },
  },
  methods: {
    updatePosition() {
      this.position.y = window.innerHeight - 50; // Adjust for chat height
    },
    getUnreadMessagesCount() {
      this.unreadMessagesCount = 0;
      Object.values(this.chats).forEach((c) => {
        if (c.unreadCount > 0) {
          this.unreadMessagesCount++;
        }
      });
    },
    chatOpen() {
      this.chatMinimized = !this.chatMinimized;
      this.scrollToBottom();
    },
    selectLead(lead_id) {
      this.selectedLeadId = lead_id;
      this.scrollToBottom();
      this.getUnreadMassages(lead_id);
    },
    closeChat() {
      this.selectedLeadId = null;
    },
    handlePqOpenNotification(notification) {
      this.subscribeToChannel(notification.data.lead_id);
    },
    subscribeToChannel(lead_id) {
      let that = this;
      that.$echo
        .channel(`pq-chat-${that.userId}-${lead_id}`)
        .listen("PqChatMsg", (e) => {
          const existingChat = that.chats[e.lead_id]?.chat || [];
          if (!existingChat.some((msg) => msg.id === e.id)) {
            that.chats[e.lead_id].chat.push(e);
          }

          if (that.selectedLeadId == e.lead_id) {
            that.getUnreadMassages(e.lead_id);
          } else {
            if (e.is_client && !e.read_at) {
              that.chats[e.lead_id].unreadCount = that.chats[
                e.lead_id
              ].chat.filter((msg) => msg.is_client && !msg.read_at).length;
            }
          }
          that.scrollToBottom();
          that.getUnreadMessagesCount();
        });
      if (!that.chats[lead_id]) {
        that.getLeadChat(lead_id);
      }
      let storedLeads =
        JSON.parse(localStorage.getItem("subscribedLeads")) || [];

      if (!storedLeads.includes(lead_id)) {
        storedLeads.push(lead_id);
        localStorage.setItem("subscribedLeads", JSON.stringify(storedLeads));
      }
    },
    sendMessage(selectedLeadId) {
      if (this.newMessage.trim() === "") return;

      const message = {
        is_client: false,
        lead_id: selectedLeadId,
        user_id: this.userId,
        msg: this.newMessage.trim(),
      };
      this.axios.post(`/pq-chat/msg-send`, message).then(() => {
        this.newMessage = "";
        this.scrollToBottom();
      });
    },
    getUnreadMassages(selectedLeadId) {
      let that = this;
      const unreadMessages = that.chats[selectedLeadId].chat.filter(
        (msg) => msg.is_client && !msg.read_at
      );

      let ids = unreadMessages.map((msg) => msg.id);

      if (ids.length > 0) {
        that.axios
          .get(`/pq-chat/mark-as-read`, { params: { ids } })
          .then(() => {
            that.chats[selectedLeadId].chat.forEach((msg) => {
              if (msg.is_client && msg.read_at === null) {
                msg.read_at = new Date();
              }
            });
            that.chats[selectedLeadId].unreadCount = 0;
            that.getUnreadMessagesCount();
            ids = null;
          });
      }
    },
    getMassages() {
      let that = this;
      const lead_Ids = JSON.parse(
        localStorage.getItem("subscribedLeads") || "[]"
      )
        .map(Number)
        .filter((num) => !isNaN(num) && num !== 0);

      that.axios
        .get(`/pq-chat/unread-chats`, { params: { leadIds: lead_Ids } })
        .then((res) => {
          const leadIds = Object.keys(res.data.data.chats)
            .map(Number)
            .filter((num) => !isNaN(num) && num !== 0);

          const allLeadIds = [...new Set([...lead_Ids, ...leadIds])];

          if (allLeadIds.length > 0) {
            localStorage.setItem("subscribedLeads", JSON.stringify(allLeadIds));

            allLeadIds.forEach((lead_id) => {
              if (!that.chats[lead_id]) {
                that.$set(that.chats, lead_id, {
                  lead: lead_id,
                  client: res.data.data.clients[lead_id],
                  chat: res.data.data.chats[lead_id],
                  unreadCount:
                    res.data.data.chats[lead_id]?.filter(
                      (msg) => msg.is_client && msg.read_at === null
                    ).length ?? 0,
                });
              }
              that.subscribeToChannel(lead_id);
            });
            that.getUnreadMessagesCount();
          }
        })
        .catch((error) => {
          console.error("Error fetching unread chats:", error);
        });
    },
    getLeadChat(lead_id) {
      let that = this;
      that.axios.get(`/pq-chat/lead-chat/` + lead_id).then((res) => {
        that.$set(that.chats, lead_id, {
          lead: lead_id,
          client: res.data.data.client,
          chat: res.data.data.chat,
          unreadCount:
            res.data.data.chat?.filter(
              (msg) => msg.is_client && msg.read_at === null
            ).length ?? 0,
        });
        that.getUnreadMessagesCount();
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatArea = this.$refs.chatArea;
        if (chatArea) {
          chatArea.scrollTop = chatArea.scrollHeight;
        }
      });
    },
    startDrag(e) {
      this.drag = true;
      this.offset.x = e.clientX - this.position.x;
      this.offset.y = e.clientY - this.position.y;
      document.addEventListener("mousemove", this.doDrag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    doDrag(e) {
      if (this.drag) {
        this.position.x = e.clientX - this.offset.x;
        this.position.y = e.clientY - this.offset.y;
      }
    },
    stopDrag() {
      this.drag = false;
      document.removeEventListener("mousemove", this.doDrag);
      document.removeEventListener("mouseup", this.stopDrag);
    },
  },
};
